<template>
  <div id="pnf-404" class="pnf-404 bg-blue-soft">
    <h1><span class="text-danger"><i class="fa fa-times-circle animation"></i> Error-404 <i class="fa fa-times-circle animation reverse"></i></span><br> Page Not Found !!!</h1>
  </div>
</template>

<script>
  export default {
    name: "404",
  }
</script>

<style scoped>
  .pnf-404{
    min-height: 300px;
    position: relative;
    box-sizing: border-box;
    font-family: Rockwell;
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .animation{
    animation: rotate 3s infinite;
    animation-timing-function: linear;
  }

  .delay{
    animation-delay: 1s;

  }

  .reverse{
    animation-direction: reverse;
  }

  @keyframes rotate {
    from {transform: rotate(0deg)}
    to {transform: rotate(360deg)}
  }
</style>